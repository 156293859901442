import React, { useContext, useEffect, useState } from "react"
import BlockContent from "@sanity/block-content-to-react"
import Layout from "../../components/layout"
import SectionColumns from "../../components/elements/section-columns"
import { StoreContext } from "../../context/store-context"
import { asSingleWithAmount, getDeliveryObject } from "../../util"
import AnchorButton from "../../components/elements/anchor-button"
import CheckoutHeader from "../../components/header/checkout-header"
import { useQueryParam, StringParam } from "use-query-params"
import { sessionNames } from "../../util/constants"
import { useStaticQuery, graphql } from "gatsby"
import { getByCode } from "../../util/iso"
import SEO from "../../components/seo"

// txnid=259974978
// orderid=5t1f04062020091819
// amount=9800
// currency=208
// date=20200604
// time=0919
// txnfee=0
// paymenttype=3
// cardno=333333XXXXXX3000
// hash=e0d451a4a935387784e9b93b701a6b65

const Accept = () => {
  const { cart, freeDeliveryCap } = useContext(StoreContext)
  const [rawItems, setRawItems] = useState([])
  const [orderItems, setOrderItems] = useState([])
  const [serverResult, setServerResult] = useState({})
  const [pageState, setPageState] = useState({ error: false, loading: true })
  const [currentDelivery, setCurrentDelivery] = useState({})
  const [customer, setCustomer] = useState({})
  const [code, setCode] = useState(null)

  const [txnid] = useQueryParam("txnid", StringParam)
  const [orderid] = useQueryParam("orderid", StringParam)
  const [amount] = useQueryParam("amount", StringParam)
  const [currency] = useQueryParam("currency", StringParam)
  const [date] = useQueryParam("date", StringParam)
  const [time] = useQueryParam("time", StringParam)
  const [txnfee] = useQueryParam("txnfee", StringParam)
  const [paymenttype] = useQueryParam("paymenttype", StringParam)
  const [cardno] = useQueryParam("cardno", StringParam)
  const [hash] = useQueryParam("hash", StringParam)
  const [veraorderid] = useQueryParam("veraorderid", StringParam)

  const { sanitySiteInfo } = useStaticQuery(graphql`
    query {
      sanitySiteInfo(site_id: { current: { eq: "veracom" } }) {
        shop_configuration {
          thank_you_title
          thank_you_message {
            _type
            _key
            _rawContent
          }
        }
      }
    }
  `)

  // use effect to make fetch request to function and verify hash

  const thankYouTitle =
    sanitySiteInfo &&
    sanitySiteInfo.shop_configuration &&
    sanitySiteInfo.shop_configuration.thank_you_title

  const thankYouContent =
    sanitySiteInfo &&
    sanitySiteInfo.shop_configuration &&
    sanitySiteInfo.shop_configuration.thank_you_message

  const fullPrice = () => {
    return rawItems.reduce(
      (acc, curr) => acc + curr.variant.variant_info_price,
      0
    )
  }

  const isFreeDelivery = code
    ? fullPrice() - (fullPrice() / 100) * code.code_amount >= freeDeliveryCap &&
      currentDelivery &&
      currentDelivery.name !== "international"
    : fullPrice() >= freeDeliveryCap &&
      currentDelivery &&
      currentDelivery.name !== "international"

  const BillingField = ({ children }) => (
    <p style={{ marginBottom: 10 }}>{children}</p>
  )

  const getPaymentMethod = () => {
    switch (paymenttype) {
      case "1":
        return "Dankort/Visa Dankort"
      case "3":
        return "Visa / Visa Electron"
      case "4":
        return "Mastercard"
      case "29":
        return "MobilePay"
    }
  }

  useEffect(() => {
    fetch(`${process.env.GATSBY_API_BASE_URL}api/verifypayment`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        txnid,
        orderid,
        amount,
        currency,
        date,
        time,
        txnfee,
        paymenttype,
        cardno,
        veraorderid,
        hash,
      }),
    })
      .then(x => {
        if (x.status !== 200) {
          setPageState({ loading: false, error: true })
        } else {
          x.json().then(result => {
            setServerResult({ ...result })
            setPageState({ loading: false, error: false })
            const items = result.order.products.map(p => {
              const imageUrl = result.products.find(x => x._id === p.product_id)
                .product_image.url
              return { ...p, image: imageUrl }
            })
            setRawItems([...items])
            setOrderItems([...asSingleWithAmount(items)])
            setCurrentDelivery({
              ...getDeliveryObject(result.order.delivery_method),
            })
            setCustomer({ ...result.customer })

            if (result.order.order_code) {
              setCode(result.order.order_code)
            }

            for (let key in sessionNames) {
              localStorage.removeItem(sessionNames[key])
            }
          })
        }
      })
      .catch(err => setPageState({ loading: false, error: true }))
  }, [])

  return (
    <Layout>
      <SEO title="Review Order" />
      <CheckoutHeader active="review" />
      {pageState.error ? (
        <div>Error</div>
      ) : (
        <>
          {pageState.loading ? (
            <div style={{ height: 500 }}>
              <div
                style={{ marginTop: 200 }}
                className="loader is-loading"
              ></div>
            </div>
          ) : (
            <SectionColumns isCentered>
              <div className="column is-half">
                <h1 className="cart__heading" style={{ textAlign: "center" }}>
                  {thankYouTitle && thankYouTitle}
                </h1>
                <div
                  style={{
                    fontSize: "1.125rem",
                    color: "#757575",
                    textAlign: "center",
                  }}
                >
                  {thankYouContent &&
                    thankYouContent.map(c => (
                      <BlockContent key={c._key} blocks={c._rawContent} />
                    ))}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1rem",
                    marginBottom: "1.5rem",
                  }}
                >
                  <AnchorButton to="/">Go to front page</AnchorButton>
                </div>
                <h3
                  className="cart__heading"
                  style={{ marginBottom: "0.5rem" }}
                >
                  Order Details
                </h3>
                <div
                  className="cart__checkout-bag"
                  style={{ marginBottom: "1rem" }}
                >
                  {orderItems.map(product => {
                    return (
                      <div
                        key={product.product_id}
                        className="columns is-gapless is-mobile"
                      >
                        <div className="column is-3-tablet is-2-desktop">
                          <div className="cart__checkout-bag-image">
                            <div
                              style={{
                                position: "relative",
                                overflow: "hidden",
                              }}
                              className={`gatsby-image-wrapper`}
                            >
                              <div
                                aria-hidden="true"
                                style={{ width: "100%", paddingBottom: "125%" }}
                              ></div>
                              <img
                                src={`${product.image}?fit=crop`}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                  objectPosition: "center center",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="column is-8-tablet">
                          <div className="cart__checkout-bag-title-wrapper">
                            <p className="cart__checkout-bag-title">
                              {product.product_name}
                            </p>
                            <p className="cart__checkout-bag-info">
                              <span style={{ marginRight: "1rem" }}>
                                {product.variant_type}{" "}
                                {product.variant.variant_info_value}
                              </span>
                              <span>
                                Amount: {product.amount} x{" "}
                                {product.variant.variant_info_price} DKK
                              </span>
                            </p>
                          </div>
                        </div>
                        <div
                          className="column"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "0.5rem",
                          }}
                        >
                          {product.amount * product.variant.variant_info_price}{" "}
                          DKK
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="cart__overview">
                  <h3
                    className="cart__heading"
                    style={{ marginBottom: "0.5rem" }}
                  >
                    Summery
                  </h3>
                  <div className="cart__info-box" style={{ fontSize: "1rem" }}>
                    <div className="cart__price-info">
                      <p>Subtotal:</p>
                      <p style={{ color: "#757575" }}>{fullPrice()} DKK</p>
                    </div>
                    {code && (
                      <div className="cart__price-info">
                        <p>Discount:</p>
                        <p style={{ color: "#757575" }}>
                          - {Math.round((fullPrice() / 100) * code.code_amount)}{" "}
                          DKK
                        </p>
                      </div>
                    )}
                    <div
                      style={{ marginBottom: 0 }}
                      className="cart__price-info"
                    >
                      <p>Shipping:</p>
                      <p style={{ color: "#757575" }}>
                        {isFreeDelivery
                          ? 0
                          : getDeliveryObject(
                              serverResult.order.delivery_method
                            ).price}{" "}
                        DKK
                      </p>
                    </div>
                    <p style={{ color: "#757575", marginBottom: "1rem" }}>
                      {currentDelivery.title}
                    </p>
                    <div className="cart__price-info">
                      <p>Payment method:</p>
                      <p style={{ color: "#757575" }}>{getPaymentMethod()}</p>
                    </div>
                    <hr
                      style={{
                        border: "0.5px solid black",
                        marginBottom: "1rem",
                      }}
                    />
                    <div
                      style={{ marginBottom: 0, fontWeight: "bold" }}
                      className="cart__price-info"
                    >
                      <p>Total:</p>
                      <p>
                        {isFreeDelivery
                          ? code
                            ? fullPrice() -
                              Math.round((fullPrice() / 100) * code.code_amount)
                            : fullPrice()
                          : code
                          ? fullPrice() -
                            Math.round((fullPrice() / 100) * code.code_amount) +
                            getDeliveryObject(
                              serverResult.order.delivery_method
                            ).price
                          : fullPrice() +
                            getDeliveryObject(
                              serverResult.order.delivery_method
                            ).price}{" "}
                        DKK
                      </p>
                    </div>
                  </div>
                  <h3
                    className="cart__heading"
                    style={{ marginTop: "1rem", marginBottom: "0.5rem" }}
                  >
                    Billing details
                  </h3>
                  <BillingField>
                    {customer.firstname} {customer.surname}
                  </BillingField>
                  <BillingField>{customer.address}</BillingField>
                  {customer.address_2 && (
                    <BillingField>{customer.address_2}</BillingField>
                  )}
                  <BillingField>
                    {customer.postal_code} {customer.city}
                  </BillingField>
                  <BillingField>
                    {getByCode(customer.country).name}
                  </BillingField>
                  <BillingField>
                    <strong>Phone:</strong> {customer.phone_number}
                  </BillingField>
                  <BillingField>
                    <strong>Email:</strong> {customer.email}
                  </BillingField>
                </div>
              </div>
            </SectionColumns>
          )}
        </>
      )}
    </Layout>
  )
}

export default Accept
