import React from "react"

const CheckoutHeader = props => {
  const links = [
    {
      title: "1) Cart",
      key: "cart",
    },
    {
      title: "2) Checkout",
      key: "checkout",
    },
    {
      title: "3) Order review",
      key: "review",
    },
  ]
  return (
    <ul className="checkout-header">
      {links.map(link => (
        <li
          className={`checkout-header__item ${
            props.active === link.key ? "active" : ""
          }`}
          key={link.key}
        >
          {link.title}
        </li>
      ))}
    </ul>
  )
}

export default CheckoutHeader
